import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { Action, setLoading } from "../../actions/";
import { getPdf } from "../../actions/document";
import styles from "./PdfViewer.module.scss";
import {
  SpecialZoomLevel,
  Viewer,
  OpenFile,
  LoadError,
} from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { getFilePlugin } from "@react-pdf-viewer/get-file";

interface Props {
  link: string;
  getPdf: any;
  setLoading: (value: boolean) => Action;
  loading: boolean;
  onDocumentLoad?: any;
  translate: any;
}

const PdfViewer = (props: Props) => {
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file: OpenFile) => {
      const fileName = file.name.substring(file.name.lastIndexOf("/") + 1);
      return `${fileName}.pdf`;
    },
  });
  const { DownloadButton } = getFilePluginInstance;

  const renderToolbar = (Toolbar: (props: any) => any) => (
    <Toolbar>
      {(slots: any) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          Print,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div style={{ alignItems: "center", display: "flex" }}>
            <div style={{ padding: "0px 2px" }}>
              <ShowSearchPopover />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <GoToPreviousPage />
            </div>
            <div
              style={{
                padding: "0px 2px",
                color: "white",
                alignItems: "center",
                display: "flex",
              }}
            >
              <CurrentPageInput />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <GoToNextPage />
            </div>

            <div
              style={{ alignItems: "center", display: "flex", margin: "auto" }}
            >
              <div style={{ padding: "0px 2px" }}>
                <ZoomOut />
              </div>
              <div style={{ padding: "0px 2px" }}>
                <Zoom />
              </div>
              <div style={{ padding: "0px 2px" }}>
                <ZoomIn />
              </div>
            </div>

            <div style={{ padding: "0px 2px" }}>
              <DownloadButton />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Print />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const renderError = (_error: LoadError) => {
    if (props.loading) props.setLoading(false);
    let message = props.translate(
      "pdf.common.error",
      "Technical error. Please try again later."
    );

    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#e53e3e",
            borderRadius: "0.25rem",
            color: "#fff",
            padding: "0.5rem",
          }}
        >
          {message}
        </div>
      </div>
    );
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar,
  });

  const defaultScale =
    window.innerHeight < 1060 ? 1.0 : SpecialZoomLevel.PageFit;

  const csrfToken = (document.querySelector('meta[name="csrf-token"]') as any)
    ?.content;

  return (
    <div className={styles.pdfViewerContainer}>
      <Viewer
        theme="dark"
        plugins={[defaultLayoutPluginInstance, getFilePluginInstance]}
        withCredentials={true}
        httpHeaders={{
          "X-CSRF-TOKEN": csrfToken,
        }}
        renderError={renderError}
        defaultScale={defaultScale}
        onDocumentLoad={(e) => props.onDocumentLoad && props.onDocumentLoad(e)}
        fileUrl={props.link}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.app.loading,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getPdf: bindActionCreators(getPdf, dispatch),
  setLoading: (val) => dispatch(setLoading(val)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PdfViewer);
